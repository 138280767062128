import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import 'resources/style/pagination.css'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'
import fetchData from 'resources/api/api'

import {
  Layout,
  Seo,
  Header,
  Hero,
  Plans,
  Testimonials,
  LeadCapture,
  Footer,
  Goals,
  FooterBanner,
} from 'components'

import { Teachers } from 'components/teachers/teachers'
import { MainFaq } from 'components/faq/main-faq'
import { scrollIntoViewBasedOnHash } from 'resources/helpers/view-scroller'

export default function Home ({ data }) {
  const [isBlackFridayHome, setIsBlackFridayHome] = useState(false)
  const [countdownTimer, setCountdownTimer] = useState(false)
  const [url, setUrl] = useState()
  const [apiData, setApiData] = useState()

  const isCountdownActive = () => {
    const today = new Date()
    const init = new Date('2023-12-26T03:01:00.000Z') // + 3 horas
    const end = new Date('2024-01-02T02:59:59.000Z') // + 3 horas

    return today >= init && today <= end
  }

  useEffect(() => {
    setUrl(typeof window !== 'undefined' ? window.location.search : '')
    scrollIntoViewBasedOnHash()
  }, [data.api.isBlackDays, url])

  useEffect(() => {
    const api = async () => {
      try {
        const data = await fetchData()
        setApiData(data)
        setIsBlackFridayHome(data.isBlackDays)
        if (isCountdownActive()) { setCountdownTimer(true) }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    api()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <BlackFridayContext.Provider value={isBlackFridayHome}>
      <Layout>
        <Seo />
        <Header utm={url} />
        <>
          <Hero banners={apiData?.bannersSite} utm={url} />
          <Plans plans={apiData?.plans} utm={url} />
        </>
        <Goals utm={url} />
        {!isBlackFridayHome && <Testimonials testimonials={data.api.allTestimonials} utm={url} />}
        <Teachers teachers={data.api.teachers.nodes} isBlackFridayHome={isBlackFridayHome} />
        <MainFaq blackFridayData={data.api.blackFriday} />
        {!isBlackFridayHome && <LeadCapture utm={url} />}
        {countdownTimer && (<FooterBanner />)}
        <Footer />
      </Layout>
    </BlackFridayContext.Provider>
  )
}

Home.propTypes = {
  data: t.object,
}

export const query = graphql`
  query {
    api {
      isBlackDays

      blackFriday {
        startDate
        endDate
      }

      classGroupsByDiscipline {
        discipline
        classGroups {
          title
          image
        }
      }

      teachers(isVisibleInSite: true) {
        nodes {
          id
          name
          siteAvatar
          biography
          viewOrder
        }
      }

      allTestimonials(testimonialType: "text") {
        name
        image
        content
        description
        viewOrder
      }
    }
  }
`
